import React from "react";

const App = () => {
  return (
    <div className="App">
      <header className="App-header">Welcome to my page!</header>
    </div>
  );
};

export default App;
